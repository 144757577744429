import {Subtitle} from "@panwds/react-ui";
import {useTranslate} from "../../../../customHooks";
import {FormControl, Grid} from "@material-ui/core";
import {Field, FormSpy, useForm, useFormState} from "react-final-form";
import {
    PANWDSCheckboxField,
    PanwdsTableLightWithFilters,
    PANWDSRadioField
} from "../../../../components/PANWDSElements";
import {Row} from "../../../../components/FormElements";
import * as React from "react";
import {useMemo, useState} from "react";
import {isEmpty} from "lodash";
import {PANWDSRadio} from "../../../../components/PANWDSElements/PANWDSRadioField";

const Index = () => {
    const formState = useFormState();
    const translate = useTranslate();

    const values = formState.values;

    const egressNatPublicIpColumns = useMemo(() => [
        {
            accessor: 'IPAddress',
            Header: 'Public IP',
            columnSize: 5,
            Cell: ({ row }: any) => <span>{row?.original?.IPAddress}</span>,
        },
        {
            accessor: 'IpStatus',
            Header: 'Used',
            Cell: ({ row }: any) => <span>{row?.original?.IPStatus === 'FREE' ? 'NO' : 'YES'}</span>,
        }
        ], []);

    return (
        <>
            <Row>
                <Grid item sm={12} className="noPadding" style={{paddingBottom: "1rem"}}>
                    <FormControl>
                        <Field
                            name="Firewall.EgressNAT.Enabled"
                            // @ts-ignore
                            component={PANWDSCheckboxField}
                            label={translate(`resources.firewalls.fields.EgressNAT.EnableEgressNat`)}
                            type="checkbox"
                            disabled={!values.Firewall?.EgressNAT || formState?.values?.FirewallUpdating}
                            dataMetrics={"cloudngfw-firewall-edit-egressNatEnabled"}
                            muted={translate(`resources.firewalls.fields.EgressNAT.EnableEgressNatMutedText`)}
                        />
                    </FormControl>
                </Grid>
            </Row>
            {(!isEmpty(values.Firewall?.EgressNAT) && values?.Firewall?.EgressNAT.Enabled === true && !isEmpty(values?.Status?.PublicIPs)) && <div>
                <Subtitle as={"div"} appearance={"semibold"} size={"sm"} addClassName="tw-pb-2">Public IPs</Subtitle>
                <Grid item sm={12}>
                    <FormControl>
                        <Field
                            name="Firewall.EgressNAT.Settings.IPPoolType"
                            // @ts-ignore
                            component={PANWDSRadio}
                            options={[
                                { label: translate(`resources.firewalls.fields.EgressNAT.AWSService`), value: "AWSService" },
                            ]}
                            row
                            disabled={formState?.values?.FirewallUpdating}
                            initValue={"AWSService"}
                            customStyle={{marginBottom: 0}}
                        />
                    </FormControl>
                </Grid>
                <PanwdsTableLightWithFilters
                    columns={egressNatPublicIpColumns}
                    gridData={values?.Status?.PublicIPs || []}
                    dataTestId={"cloudngfw-firewall-egress-nat-public-ip-table"}
                    title={''}
                    showToolbar={false}
                    showTileTitle={false}
                    multiSelect={false}
                    searchFilterRequired={false}
                    tableContainerStyle={{padding: '0 1.5rem 0 0'}}
                />
            </div>}
        </>
    );
};

export default Index;
