import { ChangeEvent, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Radio, RadioGroup } from "@panwds/react-ui";
import { PANFieldContainer, PANHelpText, PANMutedText } from "../FormElements/styles";
import {PANWDSLabel} from "./PANWDSLabel";

interface IRadioOptions { label: string; value: string, disabled?: boolean }

const useStyles = makeStyles({
  rowStyles: {
    display: 'flex',
    color: "#333333",
    marginBottom: '16px',
    "&>label": {
      "&>input": {
        marginLeft: '0px'
      },
      "&>span": {
        margin: '3px 16px 0 4px !important'
      }
    }
  },
  columnStyles: {
    "&>label": {
      width: "auto",
      margin: "10px 0",
    }
  },
  errorText: {
    color: "#D13C3C",
  },
});

export const PANWDSRadioField = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (props?.initValue) {
      return inputProps.onChange(inputProps.value || props?.initValue)
    }
  }, [])

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    inputProps.onChange(event.target.value)
  }

  const existerror = !!(touched && error);

  return (
    <PANFieldContainer>
        {props.title && <PANWDSLabel required={props.required} title={props.title}/>}

      <PANWDSRadio groupName={inputProps.name} onChange={onChangeValue} options={props?.options}
        initValue={inputProps.value || props?.initValue} row={props.row} disabled={props?.disabled}
        dataMetrics={props.dataMetrics}
      />
      {existerror && (<PANHelpText>{error}</PANHelpText>)}
      {props?.muted && (<PANMutedText>{props.muted}</PANMutedText>)}
    </PANFieldContainer>
  );
};

export const PANWDSRadio = ({ groupName, onChange, options, initValue, row, title, disabled, dataMetrics, customStyle }: {
  groupName: string,
  row?: boolean; onChange: any;
  initValue?: string; options: IRadioOptions[]; title?: string; disabled?: boolean, dataMetrics: string,
    customStyle?: any
}) => {
  const classes = useStyles();

  return (
    <div>
        {title && <PANWDSLabel disabled={disabled} title={title}/>}

      <RadioGroup name={groupName} className={row ? classes.rowStyles : classes.columnStyles} style={customStyle} onChange={onChange} value={initValue}>
        {options?.map((opt: any, idx: number) => (
          <Radio key={idx} dataTestId={`${idx}`} value={opt.value} checked={initValue === opt.value}
           disabled={disabled || opt?.disabled} dataMetrics={`${dataMetrics}-${opt.value}`}>
            {opt.label}
          </Radio>
        ))}
      </RadioGroup>
    </div>
  )
}
